import React, { useEffect, useRef, useState } from 'react';

import { useIntersectionObserver } from 'usehooks-ts';
import { getOrderHeaderLabel, getOrderDescription } from 'src/utils/orderUtils';
import { MODULE_NAME } from 'src/constants';
import { isSSR } from 'src/helpers/ssr';
import { LocalizationProvider } from '@lt/localization';

import TourGuide from 'src/components/Banners/TourGuide';

import classnames from 'classnames';
import { SEARCH_WIDGET_PLACE_ID } from 'src/constants/header';
import useLockedBody from 'src/hooks/useLockedBody';
import { Portal } from '@lt/components';
import Menu from './components/HeaderMenu';
import Logo from './components/HeaderLogo';
import SearchWidgetPreview from './components/HeaderSearchWidgetPreview';
import Order from './components/HeaderOrder';
import HeaderSearchForm from './components/HeaderSearchForm';

import type { PropsFromRedux } from '.';
import styles from './header.module.css';
import TopMobileAppBannerContainer from '../TopMobileAppBanner';

const baseCls = 'header';

type Props = PropsFromRedux & {
  isHomepage?: boolean;
  isAppBannerEnabled?: boolean;
  isWhiteLabel: boolean;
  shouldFetchClientInfo?: boolean;
  isWebview?: boolean;
  isTouristsStep?: boolean;
  isPayStep?: boolean;
  isHotelPayment?: boolean;
  openSidebar?: () => void;
};

const Header = ({
  isMobile,
  isAppBannerEnabled,
  isIframe,
  appName,
  orderInfo,
  cigaretteValues,
  pageSearchType,
  isTransparent,
  isWidgetShown,
  isHomepage,
  isWhiteLabel,
  availableSearchTypes,
  shouldFetchClientInfo,
  fetchClientInfo,
  initialOpenedField,
  isTouristsStep,
  isPayStep,
  isWebview,
  isHotelPayment,
  openSidebar,
}: Props) => {
  const openedRef = useRef<HTMLDivElement>(null);

  const entry = useIntersectionObserver(openedRef, {
    rootMargin: `147px 0px 100% 0px`, // отступы вокруг root-элемента (в данном случае - открытой сигареты), 147px - потому что на отлях и турах сигарета разная, а это среднее значение
    threshold: 0.3, // число или массив чисел, указывающий допустимый процент пересечения target и root (портальной и открытой сигареты)
  });

  const [isSticky, setIsSticky] = useState<boolean>(false);

  const [, setLocked] = useLockedBody();

  useEffect(() => {
    setIsSticky(!entry?.isIntersecting);
  }, [entry]);

  useEffect(() => {
    /*
     * когда Header вложен в Layout (поиск, отель, чекаут), то Layout триггерит INIT_APP,
     * по которому потом триггерится FETCH_CLIENT_INFO -- для других случаев используем shouldFetchClientInfo
     */
    if (shouldFetchClientInfo) {
      fetchClientInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isOrderPage = appName === MODULE_NAME.ORDER_CHANGING_REQUEST;

  if (isIframe) return null;

  const isSearchPage = appName === MODULE_NAME.SEARCH;
  const isSeoPage = appName === MODULE_NAME.SEO;

  const isOpenedForm = (isSearchPage || isSeoPage) && !isMobile;

  const Widget = isOrderPage ? (
    <Order
      label={getOrderHeaderLabel(orderInfo)}
      description={getOrderDescription(orderInfo)}
    />
  ) : (
    <SearchWidgetPreview />
  );

  const hasTourGuideBanner = isHomepage && !isMobile && !isWhiteLabel;

  const hasTopMobileAppBanner =
    isMobile &&
    isAppBannerEnabled &&
    !isWebview &&
    !isTouristsStep &&
    !isPayStep &&
    !isHotelPayment; // очень костыльно, но ограничений по нему много

  const headerMobileComponent = (
    <div
      className={classnames(baseCls, styles.header, {
        [styles.mobileHeader]: isMobile,
        [styles.portalledHeader]: isSeoPage,
        [styles.stickyHeader]: !isHomepage,
        [styles.wlHeader]: isWhiteLabel,
      })}
      style={{
        backgroundColor: isTransparent ? 'transparent' : '#17181a',
      }}
    >
      {isSSR ? null : <Logo isHomepage={isHomepage} />}
      {hasTourGuideBanner && <TourGuide isHomeHeader />}
      {isWidgetShown && Widget}
      {isSSR ? null : (
        <Menu
          className={classnames(styles.menu, {
            [styles.menuWithBlackColor]: isHomepage,
          })}
          isCollapsed={!isSearchPage && !isHomepage}
          openSidebar={openSidebar}
        />
      )}
    </div>
  );

  return (
    <LocalizationProvider>
      {!isSSR && hasTopMobileAppBanner && <TopMobileAppBannerContainer />}
      {!isSSR && isSeoPage && isMobile ? (
        <div
          className={styles.widgetWrapper}
          id={SEARCH_WIDGET_PLACE_ID}
          ref={openedRef}
        >
          <HeaderSearchForm
            cigaretteValues={cigaretteValues}
            pageSearchType={pageSearchType}
            isMobile={isMobile}
            availableSearchTypes={availableSearchTypes}
            inPreview
            initialOpenedField={initialOpenedField}
            setBodyLocked={setLocked}
          />
        </div>
      ) : null}

      {isOpenedForm ? (
        <div
          className={classnames(baseCls, styles.header, {
            [styles.stickyHeader]: !isHomepage && !isMobile,
            [styles.isOpenedForm]: isOpenedForm,
            [styles.wlHeader]: isWhiteLabel,
          })}
          style={{
            backgroundColor: isTransparent ? 'transparent' : '#17181a',
          }}
        >
          <HeaderSearchForm
            cigaretteValues={cigaretteValues}
            pageSearchType={pageSearchType}
            initialOpenedField={initialOpenedField}
          />
        </div>
      ) : (
        <>
          {isSeoPage
            ? isSticky && <Portal>{headerMobileComponent}</Portal>
            : headerMobileComponent}
        </>
      )}
    </LocalizationProvider>
  );
};

export default Header;
