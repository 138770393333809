/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { Image, Link } from '@lt/components';
import logoPng from 'src/images/logo/logo.png';
import logoWebp from 'src/images/logo/logo.webp';
import { i18n } from '@lt/localization';
import classNames from 'classnames';
import type { BannerType } from 'src/store/appBanners/types';
import { BANNER_TYPE } from 'src/store/appBanners/constants';

import styles from './TopMobileAppBanner.module.css';
import BannerStars from '../Banners/components/BannerStars';
import { CloseButton } from '../AuthNew/components/CloseButton';
import { LOOCK_BUTTON_TEST_ID, TOP_MOBILE_BANNER_TEST_ID } from './constants';

type Props = {
  reviewsCountText?: string;
  className?: string;
  rating?: number;
  linkToApp: string;
  appName: string;
  onClickButton: () => void;
  onClose: () => void;
  trackShow: (value: BannerType[keyof BannerType]) => void;
};

export const TopMobileAppBanner = ({
  className,
  reviewsCountText = '10 тыс.',
  rating = 5,
  linkToApp,
  appName,
  onClickButton,
  onClose,
  trackShow,
}: Props): JSX.Element => {
  useEffect(() => {
    trackShow(BANNER_TYPE.UPPER_MOBILE_BANNER_TO_APP);
    // задезейблил так как trackShow это функция и ссылка на нее можем меняться
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fullStarsCount = Math.floor(rating);
  const decimalPart = rating - fullStarsCount;
  const isSeopage = appName === 'seo';

  return (
    <div
      className={classNames(styles.container, className, {
        [styles.seopage]: isSeopage,
      })}
      data-testid={TOP_MOBILE_BANNER_TEST_ID}
    >
      <CloseButton
        className={styles.wrapper}
        width={20}
        height={20}
        onClick={onClose}
      />
      <div className={styles.pictureBlock}>
        <Image
          className={styles.logo}
          src={logoWebp}
          fallback={logoPng}
          alt="Level.Travel logo"
        />
      </div>
      <div className={styles.textBlock}>
        <div className={styles.content}>
          <p className={styles.title}>
            {i18n.t('mobile_apps_banner.top_title')}
          </p>
          <BannerStars
            reviewsCountText={reviewsCountText}
            rating={rating}
            className={
              decimalPart !== 0 ? styles.bannerStarsDecimal : styles.bannerStars
            }
          />
        </div>
        <div
          className={styles.buttonText}
          onClick={onClickButton}
          data-testid={LOOCK_BUTTON_TEST_ID}
        >
          <Link href={linkToApp} target="_blank">
            {i18n.t('mobile_apps_banner.look')}
          </Link>
        </div>
      </div>
    </div>
  );
};
